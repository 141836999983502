import escapeHTML from "escape-html";
import { get as g } from "lodash";
import moment from "moment";

import { formatPrice } from "./utils";

// TODO: Get contract HTML from the server using contract.html.twig so it doesn't need to be copy pasted.
// And probably put it in the iframe.

export const contractMuster = `
<h1 style="text-align: center">SMLOUVA O DÍLO MEZI AUTOREM A KOLEGOU</h1>
<div style="text-align: center">uzavřená podle ustanovení § 2586 a násl. zákona č. 89/2012 Sb., občanský zákoník, ve
    znění pozdějších předpisů (<b>„Občanský zákoník“</b>)<br>
    (<b>„Smlouva“</b>)
</div>
<p><b>SMLUVNÍ STRANY</b></p>
<table>
    <tr>
        <td style="padding-right: 2em;">(1)</td>
        <td>
            <b>{{authorName}}</b><br>
            trvale bytem {{authorAddress}}<br>
            datum narození {{authorBirthday}}<br>
            (<b>„Autor“</b>)<br>
            <br>a<br>
        </td>
    </tr>
    <tr>
        <td>(2)</td>
        <td>
            <b>{{contributorName}}</b><br>
            trvale bytem {{contributorAddress}}<br>
            datum narození {{contributorBirthday}}<br>
            (<b>„Kolega“</b>)<br>
            (Autor a Kolega dále též jednotlivě <b>„Strana“</b> a společně <b>„Strany“</b>)
        </td>
    </tr>
</table>
<div>
    <table>
        <tr>
            <td><b>PREAMBULE</td>
        </tr>
        <tr>
            <td>A.</td>
            <td>Tato Smlouva je uzavírána mezi Autorem jako objednatelem a Kolegou jako dodavatelem.</td>
        </tr>
        <tr>
            <td>B.</td>
            <td>Tato Smlouva je uzavírána v rámci Platformy Pointa a aplikují se na ni Obchodní podmínky Pointa a
                Obchodní podmínky Pointa – Podmínky Předprodeje (<b>„VOP“</b>) uzavřené v rámci Platformy Pointa, se
                kterými Strany při registraci na Platformu souhlasily.</td>
        </tr>
        <tr>
            <td>C.</td>
            <td>Termíny uvedené v této Smlouvě s velkými počátečními písmeny, aniž by byly definovány v této
                Smlouvě, mají význam přiřazený jim ve VOP.</td>
        </tr>
        <tr>
            <td>D.</td>
            <td>Autor je autorem literárního autorského díla s pracovním názvem {{projectName}}, které zamýšlí
                spojit s jinými díly (například ilustracemi) a vydat přes Platformu Pointa v knižní tiskové anebo
                audio podobě nebo jako e-book (<b>„Kniha“</b>).</td>
        </tr>
        <tr>
            <td>E.</td>
            <td>Kolega je jedním z profesionálních odborníků uvedených ve VOP (grafik, redaktor aj.) a má zájem
                spolupracovat s Autorem za podmínek dle této Smlouvy.</td>
        </tr>
        <tr>
            <td>F.</td>
            <td>Předmětem této Smlouvy je povinnost Kolegy poskytovat Autorovi určité služby, které jsou uvedeny v
                Příloze č. 1 k této Smlouvě (<b>„Služby“</b>), a povinnost Autora za tyto Služby zaplatit dohodnutou
                odměnu. Při Službách může Kolega vytvářet dílo (<b>„Dílo“</b>), které může být předmětem
                autorskoprávní
                ochrany. Není-li dále uvedeno jinak, představuje pojem Služba i Dílo.</td>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr>
            <td><b>1.</b></td>
            <td><b>PŘEDMĚT SMLOUVY</b></td>
        </tr>
        <tr>
            <td>1.1</td>
            <td>Kolega se zavazuje, že bude na základě pokynů a objednávek Autora poskytovat Služby.</td>
        </tr>
        <tr>
            <td>1.2</td>
            <td>Autor se zavazuje za řádně provedené Služby zaplatit Kolegovi odměnu dle této Smlouvy.</td>
        </tr>
        <tr>
            <td>1.3</td>
            <td>Kolega poskytuje Služby dle této Smlouvy svým jménem a na svůj účet. Kolega je povinen
                poskytovat Služby s odbornou péčí a v souladu se zájmy Autora.</td>
        </tr>
        <tr>
            <td>1.4</td>
            <td>Strany se dohodly, že Kolega začne se zhotovením Díla a poskytováním Služeb pouze za
                předpokladu, že od Autora nebo Pointy bude potvrzeno, že Autor má na vydání Knihy, a tedy i na
                zaplacení Odměny Kolegovi za Dílo, dostatek financí v souladu s VOP.
            </td>
        </tr>
        <tr>
            <td>1.5</td>
            <td>Strany se dohodly, že jméno Kolegy bude uvedeno v tiráži Knihy, je-li to pro odbornost Kolegy
                obvyklé.</td>
        </tr>
        <tr>
            <td>1.6</td>
            <td>Kolega je povinen dodat bezvadné a řádné Služby nejpozději do 5 měsíců od potvrzení dle čl. 1.4.
                V případě, že se jedná o Služby přímo souvisejících s úpravou Autorova literárního díla je Kolega
                povinen tyto Služby provádět nejpozději 2 měsíce od okamžiku, kdy mu Autor literární dílo
                zpřístupnil jako celek. Toto ustanovení se nepoužije pro Služby dodávané Kolegou až po vydání Knihy.
            </td>
        </tr>
        <tr>
            <td>1.7</td>
            <td>Strany si před Předprodejem (či složením financí dle VOP) dohodnou předběžné milníky, které
                odsouhlasí tým Pointy. Po úspěšném nashromáždění financí dle VOP si Strany dohodnou závazné milníky
                (které se nebudou od předběžných milníků podstatně lišit) a které odsouhlasí tým Pointy. Pointa může
                požadovat od Stran doložení řádného splnění jednotlivých milníků.
            </td>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr>
            <td><b>2.</b></td>
            <td><b>LICENČNÍ UJEDNÁNÍ</b></td>
        </tr>
        <tr>
            <td>2.1</td>
            <td>Ustanovení tohoto článku se aplikuje pouze, pokud při poskytování Služeb vznikne dílo naplňující
                definici autorského díla dle zákona č. 121/2000 Sb., o právu autorském, o právech souvisejících s
                právem autorským a o změně některých zákonů (<b>„Autorský zákon“</b>).
            </td>
        </tr>
        <tr>
            <td>2.2</td>
            <td>Kolega prohlašuje, že je jediným nositelem veškerých práv výkonného autora a dalších případných
                autorských práv či práv souvisejících s právem autorským plynoucích z Autorského zákona, a že je
                oprávněn v rozsahu dle tohoto článku (Licenční ujednání) tato práva poskytnout Autorovi.</td>
        </tr>
        <tr>
            <td>2.3</td>
            <td>
                Kolega tímto poskytuje Autorovi výhradní právo užít Dílo dle této Smlouvy:
                <table>
                    <tr>
                        <td>(a)</td>
                        <td>v časovém rozsahu na dobu trvání 50 let od podpisu Smlouvy</td>
                    </tr>
                    <tr>
                        <td>(b)</td>
                        <td>v územním rozsahu pro celý svět;</td>
                    </tr>
                    <tr>
                        <td>(c)</td>
                        <td>pro jakýkoliv způsob užití; a</td>
                    </tr>
                    <tr>
                        <td>(d)</td>
                        <td>v neomezeném množstevním rozsahu;</td>
                    </tr>
                </table>
                <br>
                v souladu s dalšími podmínkami Smlouvy (<b>„Licence“</b>).
            </td>
        </tr>
        <tr></tr>
        <tr>
            <td>2.4</td>
            <td>Licence zahrnuje oprávnění Autora vykonávat ve vztahu k Dílu také zejména následující činnosti:
                zveřejnění, úpravy včetně dopracování, zpracování, spojení s jiným dílem, zařazení do díla
                souborného</td>
        </tr>
        <tr>
            <td>2.5</td>
            <td>Autor je oprávněn poskytnout sublicenci jako je udělena Licence, a to bez nutnosti dalšího
                souhlasu Kolegy.</td>
        </tr>
        <tr>
            <td>2.6</td>
            <td>Odměna za udělení Licence je již zahrnuta v odměně za Dílo / Služby dle této Smlouvy. Autor není
                povinen Licenci využít.</td>
        </tr>
        <tr>
            <td>2.7</td>
            <td>Kolega dále výslovně svoluje s užitím jeho jména, resp. uměleckého jména a fotografie při šíření
                a užití Díla a Knihy a finální podoby audioknihy, jakož i při dalších formách propagace Díla a Knihy
                a finální podoby audioknihy, včetně všech reklamních a informačních materiálů. Stejně tak poskytne
                Kolega bezplatně součinnost při propagaci a marketingových aktivitách vztahujících se k Dílu a Knize
                a finální podobě audioknihy.</td>
        </tr>
        <tr>
            <td>2.8</td>
            <td>Aniž by tím byla dotčena výhradnost Licence, zavazuje se Autor Kolegovi (a stejný závazek platí
                i při případném udělování sublicencí), že nebude využívat Dílo k účelům, které nejsou přímo či
                nepřímo spjaty s Knihou, jejím námětem, jejím obsahem a případně Knihou jako předlohou.</td>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr>
            <td><b>3.</b></td>
            <td><b>ODMĚNA A PLATEBNÍ PODMÍNKY</b></td>
        </tr>
        <tr></tr>
        <tr>
            <td>3.1</td>
            <td>Celkové nastavení, vyčíslení a počítání Odměn dle této Smlouvy je vždy v souladu s pravidly
                uvedenými ve VOP. Zejména Strany tímto potvrzují a souhlasí s tím, že veškeré platby a odměny dle
                této Smlouvy budou hrazeny přes a s pomocí Pointy, jak je blíže uvedeno ve VOP.</td>
        </tr>
        <tr>
            <td>3.2</td>
            <td>Strany se dohodly, že celková odměna, kterou Autor zaplatí Kolegovi za poskytnutí Služeb (či
                provedení Díla), činí {{price}}&nbsp;Kč (<b>„Cena“</b>).
        </tr>
        <tr>
            <td>3.3</td>
            <td>Cena je mezi Stranami výslovně sjednána jako nejvyšší možná a nepřekročitelná. Kolega prohlašuje, že
                Cena zahrnuje veškeré jeho náklady spojené s provedením Díla poskytnutí Služeb, zejména odměnu za
                poskytnutí Licence.</td>
        </tr>
        <tr>
            <td>3.4</td>
            <td>Cena je splatná do 30 dnů od vystavení faktury či jiného aplikovatelného dokladu, fakturu či obdobný
                doklad je Kolega oprávněn vystavit nejdříve okamžikem akceptace Služeb (či Díla) bez výhrad Autora.
                Strany se dohodly, že účetní formality může za Strany administrovat Pointa. Cenu uhradí Autor skrze
                Pointu na bankovní účet sdělený Kolegou.</td>
        </tr>
        <tr>
            <td>3.5</td>
            <td>Strany se dohodly, že při plnění dle této Smlouvy nebudou vypláceny zálohy nedohodnou-li se
                Strany jinak</td>
        </tr>
        <tr>
            <td>3.6</td>
            <td>V případě, že kterákoliv ze Stran nedokončí Dílo včas nebo nestihne dohodnutý milník, je povinna
                uhradit druhé Straně na vyzvání smluvní pokutu ve výši 10.000 Kč (slovy: deset tisíc korun českých).
                Náhrada škody není smluvní pokutou dotčena.</td>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr>
            <td><b>4.</b></td>
            <td><b>ZPŮSOB PROVEDENÍ DÍLA</b></td>
        </tr>
        <tr></tr>
        <tr>
            <td>4.1</td>
            <td>Kolega je povinen provést Dílo a poskytnout Služby se znalostí a péčí, která je očekávána od
                odborníka, který má veškeré dostupné požadované znalosti a nejnovější relevantní zkušenosti v oboru.
                Autor je povinen poskytovat Kolegovi součinnost nezbytně nutnou pro řádné plnění této Smlouvy.</td>
        </tr>
        <tr>
            <td>4.2</td>
            <td>Kolega je povinen provést Dílo a poskytnout Služby v souladu s obecně závaznými právními předpisy,
                pokyny vydanými Autorem, obsahem poskytnutých informací a materiálů. Kolega se může odchýlit od jeho
                pokynů, je-li to naléhavě nezbytné v zájmu Autora a Kolega nemůže včas obdržet jeho souhlas. O
                takovém postupu je však Kolega povinen Autora informovat dodatečně v nejbližším možném termínu.</td>
        </tr>
        <tr>
            <td>4.3</td>
            <td>V případě, že Kolega zjistí, nebo při vynaložení odborné péče mohl zjistit, že informace nebo pokyny
                poskytnuté Autorem jsou chybné nebo nevhodné, je kolega povinen na tuto skutečnost Autora bez
                zbytečného odkladu upozornit. V případě, že tak Kolega neučiní, odpovídá za případně vzniklou újmu.
            </td>
        </tr>
        <tr>
            <td>4.4</td>
            <td>Veškeré údaje a informace, poskytnuté Kolegovi v souvislosti s jeho činností, bude Kolega považovat
                za důvěrné a zachová je v tajnosti. Kolega se zavazuje, že tyto údaje či informace nebude dále
                rozšiřovat, s výjimkou případů, kdy je jejich využití nezbytné nebo užitečné za účelem splnění této
                Smlouvy.</td>
        </tr>
        <tr>
            <td>4.5</td>
            <td>V případech, kdy v rámci poskytování Služeb dochází k předávání zpracovaných grafických úprav či
                sazeb knižních titulů, je Kolega povinen dodržet specifikace a pravidla uvedené v Příloze č. 2 této
                Smlouvy.</td>
        </tr>
        <tr>
            <td>4.6</td>
            <td>Strany ujednaly a souhlasí, že Autor nemá zájem na částečném plnění a je povinen převzít Dílo a
                Služby pouze v provedení dohodnutém na základě této Smlouvy. Strany si dále sjednaly, že Autor není
                povinen přijmout jakékoli plnění dle této Smlouvy, pokud se Strany nedohodnou jinak, od třetí osoby.
                Kolega se zavazuje v rámci Ceny poskytnout potřebnou součinnost při korekturách a konzultacích
                týkajících se tvorby Díla a poskytování Služeb či jeho následného užití dle požadavků Autora.</td>
        </tr>
        <tr>
            <td>4.7</td>
            <td>Strany jsou oprávněny bezplatně užít a zveřejnit jako podporu Knihy či své sebeprezentace část
                Díla či poskytnutých Služeb, a to nejvýše v rozsahu 15 % celkového rozsahu.</td>
        </tr>
        <tr>
            <td>4.8</td>
            <td>Kolega se zavazuje k tomu, že při poskytování Služeb dle této Smlouvy nebude použita umělá inteligence.</td>
        </tr>            
        <tr></tr>
        <tr></tr>
        <tr>
            <td><b>5.</b></td>
            <td><b>ZÁVĚREČNÁ USTANOVENÍ</b></td>
        </tr>
        <tr></tr>
        <tr>
            <td>5.1</td>
            <td>Tato Smlouva představuje úplné ujednání mezi Stranami ohledně předmětu v ní popsaného a je platná a
                účinná ke dni jejího podpisu poslední ze Stran (případně elektronického či jiného obdobného podpisu,
                umožní-li to Platforma). Jakékoliv změny této Smlouvy musí být učiněny písemně, formou číslovaných
                dodatků, případně elektronicky, umožní-li to Platforma.</td>
        </tr>
        <tr>
            <td>5.2</td>
            <td>V případě, že Autor či Pointa po skončení crowdfundingové kampaně nepotvrdí ve lhůtě tří (3) měsíců,
                že získali dostatek prostředků dle článku 1.4, Smlouva se ukončuje uplynutím této lhůta a smluvní
                vztah zaniká. V případě, že Pointa či Autor Kolegu písemně vyrozumí, že prostředky nezískali, končí
                smluvní vztah dle této Smlouvy okamžikem písemného doručení.</td>
        </tr>
        <tr>
            <td>5.3</td>
            <td>Strany tímto prohlašují, že na sebe berou riziko změny okolností.</td>
        </tr>
        <tr>
            <td>5.4</td>
            <td>Kolega není oprávněn postoupit svá práva podle této Smlouvy nebo tuto Smlouvu na třetí osobu bez
                předchozího písemného souhlasu Autora.</td>
        </tr>
        <tr>
            <td>5.5</td>
            <td>Pro řešení jakýchkoliv sporů z této Smlouvy nebo v souvislosti jsou výlučně příslušné obecné
                soudy České republiky. Rozhodčí doložka pro tuto Smlouvu není sjednána.</td>
        </tr>
        <tr>
            <td>5.6</td>
            <td>Jakékoli ujednání této Smlouvy, které by bylo neplatné, nezákonné nebo nevymahatelné v jakékoli
                jurisdikci, bude v rámci této jurisdikce pouze v rozsahu této neplatnosti, nezákonnosti či
                nevymahatelnosti bez toho, aby zbývající ustanovení, nebo aby takové či některé jiné ustanovení
                smlouvy bylo shledáno neplatným, nezákonným či nevymahatelným v jakékoliv další jurisdikci. Strany
                se zavazují nahradit kterékoliv neplatné či neúčinné ustanovení novým ustanovením, které bude platné
                a účinné, a které se bude co nejvíce podobat příslušnému neplatnému a neúčinnému ustanovení.</td>
        </tr>
        <tr>
            <td></td>
            <td><b>Strany tímto výslovně prohlašují, že tato Smlouva vyjadřuje jejich pravou a svobodnou vůli, na
                    důkaz čehož připojují níže své podpisy.</b></td>
        </tr>
    </table>
</div>
<div>
    <table style="width: 100%">
        <tr>
            <td colspan="2" style="text-align: center; color: deeppink">
                &#10026; &nbsp; &#10026; &nbsp; &#10026;
            </td>
        </tr>
        <tr></tr>
        <tr></tr>
        <tr>
            <td>Datum: {{today}}</td>
            <td>Datum: {{today}}</td>
        </tr>
        <tr>
            <td><b>Autor</b></td>
            <td><b>Kolega</b></td>
        </tr>
        <tr></tr>
        <tr>
          <td><b style="color: deeppink;">x</b>&nbsp;<b>{{authorName}}</b></td>
          <td><b style="color: deeppink;">x</b>&nbsp;<b>{{contributorName}}</b></td>
        </tr>
    </table>
</div>
<div style="page-break-before: always; text-align: center">
    <h1>Příloha č. 1</h1>
    <b>Popis Služeb</b>
    <p>{{description}}</p>
</div>
<div style="page-break-before: always; text-align: center">
    <h1>Příloha č. 2</h1>
    <b>Technická specifikace grafických úprav a sazeb</b>
</div>
<p>
    Pro akceptování zpracovaných sazeb titulů společností Albatros Media a.s. je nutné dodržet níže uvedené
    specifikace a pravidla, které mají za cíl mimo jiné zajistit bezproblémový průběh zálohování titulů do
    datového archivu a výrobu elektronických knih pro formáty .epub a e-PDF.
</p>
<table>
    <tr>
        <td><b>1.</b></td>
        <td><b>POŽADOVANÝ PROGRAM PRO ZPRACOVÁNÍ ZAKÁZEK</b></td>
    </tr>
    <tr>
        <td>1.1</td>
        <td>Grafické úpravy a sazby (<b>„Dílo“</b>) musí být zpracované v programech ADOBE CC, v případě zpracované
            do
            nejstarší verze ADOBE CS5. V jiných programech jako například QuarkXPress, CorelDraw, atd. není možné
            Dílo přijmout.</td>
    </tr>
    <tr>
        <td><b>2.</b></td>
        <td><b>POUŽÍVÁNÍ PÍSEM V SAZBÁCH</b></td>
    </tr>
    <tr>
        <td>2.1</td>
        <td>Pro písma v sazbách je Kolega / Kolegyně povinen / povinna použít česká písma, a to nejlépe typu 
            OpenType (<b>„OT“</b>). Kromě OT může Kolega / Kolegyně použít fonty poskytované službou Adobe Fonts</td>

    </tr>
    <tr>
        <td>2.2</td>
        <td>V případech grafických úprav obálek a obrázků v titulu je Kolega / Kolegyně oprávněn(a) použít také
            nepočeštěné grafické písmo s doplněnou nebo dotvořenou diakritikou.</td>
    </tr>
    <tr>
        <td>2.3</td>
        <td>Kolega / Kolegyně nese vždy plnou odpovědnost za celé dodané Dílo. Odpovídá tedy za to, že písmo užité v
            dodaných sazbách bylo opatřeno pod řádnou licencí a odpovídá za všechna případná porušení práv duševního
            vlastnictví třetích osob.</td>
    </tr>
    <tr>
        <td>2.4</td>
        <td>Při předání zpracované sazby je Kolega / Kolegyně povinen dodat také fonty písma či fonty použité v
            sazbě dle článku 4 této Přílohy (Předání zpracovaných dat) (<b>„Písma“</b>), takovéto předání třetí
            straně
            standardně umožňují licenční podmínky poskytovatele Písma. Licencovaná písma můžete standardně
            poskytnout třetí osobám, které pracují Vaším jménem, pokud souhlasí s používáním písma výhradně pro Vaši
            práci, souhlasí s podmínkami původní licence a po dokončení práce si neuchovávají kopie licencovaných
            písem a nesmí písmo užít pro jakoukoli jinou práci. Kolegovi / Kolegyni nadále zůstávají zachována
            všechna jeho práva k Písmu, Albatros Media a.s. je však oprávněna Písma použít pro zpracování
            elektronické knihy E-PDF titulu, který je elektronickou kopií tištěného titulu a je tedy nutné dodržet
            stejnou sazbu a grafickou úpravu.</td>
    </tr>
    <tr>
        <td><b>3.</b></td>
        <td><b>ZPRACOVANÁ SAZBA – DODRŽOVÁNÍ ZÁKLADNÍCH PRAVIDEL SAZBY</b></td>
    </tr>
    <tr>
        <td>3.1</td>
        <td>Při zpracovaní sazby titulu postupuje Kolega / Kolegyně dle následujících pravidel:
            <table>
                <tr>
                    <td>(a)</td>
                    <td>Text v sazbě je vždy zřetězený;</td>
                </tr>
                <tr>
                    <td>(b)</td>
                    <td>Pokud k tomu není dobrý důvod, tak text zarovnávat do bloku s dělením slov na konci řádku
                        (výjmka je poezie, básničky, nebo knihy pro hodně mladé děti, kdy se standardně zarovnává
                        vlevo a nedělí se slova na konci řádku);</td>
                </tr>
                <tr>
                    <td>(c)</td>
                    <td>Nepaginovat vakáty a stránky, kde je pouze ilustrace, fotka, nebo název kapitoly;</td>
                </tr>
                <tr>
                    <td>(d)</td>
                    <td>Nepaginovat úvodní stránky (signet, patitul, titul, impresum, věnování) a závěrečné strany
                        (obsah, kniha s pointou, tiráž);</td>
                </tr>
                <tr>
                    <td>(e)</td>
                    <td>Minimalizovat výskyt sirotků a vdov a řešit je úpravou nastavení sazby;</td>
                </tr>
                <tr>
                    <td>(f)</td>
                    <td>Aplikovat odstavcové a znakové styly na text, nadpisy, kapitoly;</td>
                </tr>
                <tr>
                    <td>(g)</td>
                    <td>Používat automatické funkce InDD: číslování stran, odsazení řádků, mřížka účaří, tabulátory;
                    </td>
                </tr>
                <tr>
                    <td>(h)</td>
                    <td>Používat odstavcovou zarážku;</td>
                </tr>
                <tr>
                    <td>(i)</td>
                    <td>Nastavit jednořádkovou sazbu ADOBE v odstavcových stylech;</td>
                </tr>
                <tr>
                    <td>(j)</td>
                    <td>Používat vzorové strany na stránky sazby (nepoužívat kopírování stran s paginami a
                        opakujícími se grafickými prvky z předchozích sázených stran);</td>
                </tr>
                <tr>
                    <td>(k)</td>
                    <td>Nastavit dělení českého jazyka bez užívání tvrdého dělení slov;</td>
                </tr>
                <tr>
                    <td>(l)</td>
                    <td>Dodržovat dělení dle typografických pravidel; a</td>
                </tr>
                <tr>
                    <td>(m)</td>
                    <td>Nepoužívat tvrdý enter pro zalamování řádků v odstavci.</td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <td><b>4.</b></td>
        <td><b>PŘEDÁNÍ ZPRACOVANÝCH DAT</b></td>
    </tr>
    <tr>
        <td>4.1</td>
        <td>Kolega / Kolegyně předává nejdřív tisková data titulu ve formátu: tiskové PDF, které musí splňovat
            podmínky mezinárodních standardů popsaných normou ISO 15930.</td>
    </tr>
    <tr>
        <td>4.2</td>
        <td>Po potvrzení od Albatros Media a.s., že jsou tisková data v pořádku, Kolega / Kolegyně taky předává Dílo
            v komprimovaném souboru, a to použitím příkazu „sbalit zakázku“ v programu InDesign v hlavního menu
            programu. Při použití tohoto příkazu Kolega / Kolegyně označuje pole:

            <ul class="compact">
                <li>Kopírovat písma</li>
                <li>Kopírovat navázané grafiky</li>
                <li>Aktualizovat vazby na grafiky v balíku</li>
                <li>Zahrnout písma a vazby ze skrytých a netištěných vrstev</li>
                <li>Včetně IDML</li>
            </ul>

            Tím zajistí přidání fontů, IDML souboru a vložení veškeré grafiky.
        </td>
    </tr>
</table>
`;

export const generateMuster = (data = {}) => {
  const author = g(data, "author", {});
  const contributor = g(data, "contributor", {});

  return contractMuster
    .replace(/\{\{authorName\}\}/gm, escapeHTML(`${author.name} ${author.surname}`))
    .replace(/\{\{authorAddress\}\}/g, escapeHTML(g(data, "author.address", "")))
    .replace(/\{\{authorBirthday\}\}/g, moment(g(data, "author.birthDate", "")).format("DD.M.YYYY"))
    .replace(/\{\{contributorName\}\}/g, escapeHTML(`${contributor.name} ${contributor.surname}`))
    .replace(/\{\{contributorAddress\}\}/g, escapeHTML(g(data, "contributor.address", "")))
    .replace(/\{\{contributorBirthday\}\}/g, moment(g(data, "contributor.birthDate", "")).format("DD.M.YYYY"))
    .replace(/\{\{price\}\}/g, formatPrice(g(data, "price", 0)))
    .replace(/\{\{description\}\}/g, escapeHTML(g(data, "description", "")))
    .replace(/\{\{projectName\}\}/g, escapeHTML(g(data, "project.name", "")))
    .replace(/\{\{today\}\}/g, "...");
};
